import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LogoSlider = [
  {
    img: "amazon",
    url: "",
  },
  {
    img: "hikvision",
    url: "",
  },
  {
    img: "googlecloud",
    url: "",
  },
  {
    img: "cloudflare",
    url: "",
  },
  {
    img: "whatsapp",
    url: "",
  },
  {
    img: "meta",
    url: "",
  },
];

const BrandFour = () => {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    margin: 30,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <><Slider {...settings}>
      {LogoSlider.map((val, i) => (
        <div className="item" key={i}>
          <div className="img-meta d-flex align-items-center justify-content-center">
            <a href={val.url}>
              <img src={`images/logo/${val.img}.svg`} height='74px' alt="logo" />
            </a>
          </div>
        </div>
      ))}
    </Slider><div className="row align-items-center">
      <div className="smallprint-text">
        <p>Our Partners & Integrations*</p>
      </div>
      </div></>
  );
};

export default BrandFour;
