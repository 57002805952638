import React from "react";
import { Link } from "react-router-dom";
import { RiListSettingsLine } from "react-icons/ri";
import { BiSitemap } from "react-icons/bi";
import { TbMapPins } from "react-icons/tb";
const FeaturesContent = [
  {
    bgColor: "#FFEBDB",
    icon: <RiListSettingsLine size='25px' />,
    title: "Process Control",
    desc: `Eliminate waste and improve efficiency with Manufi.`,
    dataDealy: "0",
  },
  {
    bgColor: "#E0F8F8",
    icon: <BiSitemap size='27px' />,
    title: "Inventory Management",
    desc: `Streamline your inventory management and reduce costs.
    `,
    dataDealy: "100",
  },
  {
    bgColor: "#F7EDFF",
    icon: <TbMapPins size='27px' />,
    title: "Logistics & Tracking",
    desc: `Real-time data for your clients and suppliers.`,
    dataDealy: "200",
  },
]; 

const FancyFeatureTen = () => {
  return (
    <div className="row justify-content-center mt-35 md-mt-20">
      {FeaturesContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDealy}
          key={i}
        >
          <div className="block-style-fifteen mt-40">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: val.bgColor }}
            >
              {val.icon}
            </div>
            <h3>{val.title}</h3>
            <p>{val.desc}</p>
            <Link to="/solution-management" className="mt-30">
              {" "}
              <img src="images/icon/69.svg" alt="icon" />
            </Link>
          </div>
          {/* /.block-style-ten */}
        </div>
      ))}
    </div>
  );
};

export default FancyFeatureTen;
