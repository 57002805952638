import React, {useState, useEffect} from "react";
import PriceCalculator from "./Calculate";
import { PricingContent } from "./PricingMonthly";

const PricingYeary = () => {
    const [total, setTotal] = useState(0);
    const [total2, setTotal2] = useState(0);
    const [total3, setTotal3] = useState(0);
    const [price1, setPrice1] = useState(0);
    const [price2, setPrice2] = useState(0);
    const [price3, setPrice3] = useState(0);
    const [msg, setMsg] = useState("")
  
    const handleTotal = (ttl, pkg) => {
      if (pkg === "Starter") {
        setTotal(((ttl * 12)*0.67).toLocaleString());
        setMsg('Saving 33.0%')
      } else if (pkg === "Growth") {
        setTotal2(((ttl * 12)*0.67).toLocaleString());
        setMsg('Saving 33.0%')
      } else if (pkg === "Enterprise") {
        setTotal3(((ttl * 12)*0.65).toLocaleString());
        setMsg('Saving 35%')
      } else {return}
    }
  
    useEffect(() => {

    }, [total])
  
    return (
      <div className="row justify-content-center">
        {PricingContent.map((val, i) => (
          <div
            className="col-lg-4 col-sm-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay={val.animationDelay}
            key={i}
          >
            <div className={`pr-table-wrapper ${val.activeItem}`}>
              <div className="pack-name" style={{ background: val.bgColor }}>
                {val.pcakName}
              </div>
              <div className="price">
              {/* if val.pcakName === "Starter", return {total}. if val.pcakName === "Growth", return {total2}. val.pcakName === "Enterprise", return {total3}*/}
              <span>£</span>{val.pcakName === "Starter" ? total : val.pcakName === "Growth" ? total2 : total3}
              </div>
              <div className='msg-save'>
              <span>{msg}</span>
              </div>
              <div className="pack-details">{val.packDetails}</div>
              <ul className="pr-feature">
                {val.features.map((list, i) => (
                  <li key={i}>{list}</li>
                ))}
              </ul>
              <PriceCalculator price={val.price} pkgName={val.pcakName} ttl={handleTotal} type='year' final={[total, total2, total3]}/>
              <a href="#" className="trial-button">
                Try it Free
              </a>
              <div className="trial-text">No card required, cancel any time</div>
            </div>
            {/* /.pr-table-wrapper */}
          </div>
        ))}
        
  
      </div>
    );
  };
    
export default PricingYeary;
