import React, {useState, useEffect} from "react";
import PriceCalculator from "./Calculate";

export const PricingContent = [
  {
    pcakName: "Starter",
    bgColor: "#ffecec",
    price: "62",
    packDetails: "GET MORE WITH GROWTH",
    features: [
      "Production management",
      "Inventory & supply chain",
      "Quality control and assurance",
      "Workflow automation",
      "Real-time data tracking",
      "Generate paperwork"
    ],
    animationDelay: "",
    activeItem: "",
  },
  {
    pcakName: "Growth",
    bgColor: "#E3F8EF",
    price: "74",
    packDetails: "ACCELERATE PRODUCTIVITY",
    features: [
      "Production management",
      "Inventory & supply chain",
      "Quality control and assurance",
      "Workflow automation",
      "Real-time data tracking",
      "Resource allocation",
      "Workforce management",
      "3rd party integrations",
      "Generate paperwork"
    ],
    animationDelay: "",
    activeItem: "active",
  },
  {
    pcakName: "Enterprise",
    bgColor: "#fbf3e5",
    price: "86",
    packDetails: "Get more with team",
    features: [
      "Production management",
      "Inventory & supply chain",
      "Quality control and assurance",
      "Workflow automation",
      "Real-time data tracking",
      "Resource allocation",
      "Workforce management",
      "3rd party integrations",
      "Generate paperwork"
    ],
    animationDelay: "200",
    activeItem: "",
  },
];

const PricingMonthly = () => {
  const [total, setTotal] = useState(0);
  const [total2, setTotal2] = useState(0);
  const [total3, setTotal3] = useState(0);

  const handleTotal = (ttl, pkg) => {
    if (pkg === "Starter") {
      setTotal(ttl);
    } else if (pkg === "Growth") {
      setTotal2(ttl);
    } else if (pkg === "Enterprise") {
      setTotal3(ttl);
    } else {return}
  }

  useEffect(() => {
    console.log(total)
  }, [total])

  return (
    <div className="row justify-content-center">
      {PricingContent.map((val, i) => (
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.animationDelay}
          key={i}
        >
          <div className={`pr-table-wrapper ${val.activeItem}`}>
            <div className="pack-name" style={{ background: val.bgColor }}>
              {val.pcakName}
            </div>
            <div className="price">
            {/* if val.pcakName === "Starter", return {total}. if val.pcakName === "Growth", return {total2}. val.pcakName === "Enterprise", return {total3}*/}
            <span>£</span>{val.pcakName === "Starter" ? total : val.pcakName === "Growth" ? total2 : total3}
            </div>
            <div className="pack-details">{val.packDetails}</div>
            <ul className="pr-feature">
              {val.features.map((list, i) => (
                <li key={i}>{list}</li>
              ))}
            </ul>
            <PriceCalculator price={val.price} pkgName={val.pcakName} ttl={handleTotal} type='month' final={[total, total2, total3]}/>
            <a href="#" className="trial-button">
              Try it Free
            </a>
            <div className="trial-text">No card required, cancel any time</div>
          </div>
          {/* /.pr-table-wrapper */}
        </div>
      ))}
      

    </div>
  );
};

export default PricingMonthly;
