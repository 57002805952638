import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialSix() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      img: "img_79",
      desc: ` Our team saw a massive reduction in processing time after implementing this software - highly recommend!`,
      reviewerName: "D. Chen",
      country: " Operations, UK",
    },
    {
      img: "img_78",
      desc: ` Finally found a solution that's customizable to our needs - makes production more efficient and flexible.`,
      reviewerName: "W. Kingsepp",
      country: " CTO, ESTONIA",
    },
    {
      img: "img_79",
      desc: ` Excellent customer service and support team, quick to address and fix any issues we had.`,
      reviewerName: "S. Lee",
      country: " Manager, IRELAND",
    },
    {
      img: "img_80",
      desc: ` Our logistics team is so much more efficient with this tool. Great software. Reccomended.`,
      reviewerName: "M. Hassan",
      country: " Director, UK",
    },
  ];

  return (
    <Slider {...settings}>
      {TestimonilContent.map((val, i) => (
        <div className="item" key={i}>
          <div className="feedback-wrapper">
            <p>{val.desc}</p>
            <div className="d-flex align-items-center">

              <h6 className="name">
                {val.reviewerName},<span>{val.country}</span>
              </h6>
            </div>
          </div>
          {/* End .feedback-wrapper */}
        </div>
      ))}
    </Slider>
  );
}
