import React from "react";
import { TbMap2, TbTruckDelivery } from 'react-icons/tb'
import { MdOutlineListAlt, MdOutlinePeopleAlt } from 'react-icons/md'
import { RiFlowChart } from 'react-icons/ri'
import { IoNewspaperOutline } from 'react-icons/io5'
const FeaturesContent = [
  {
    img: <TbMap2 size='40px' color="black" />,
    title: "Accept Orders",
    desc: `Our intuitive system makes it easy to accept orders from clients.`,
  },
  {
    img: <MdOutlineListAlt size='40px' color="black" />,
    title: "Manage Production",
    desc: `Say goodbye to manual tasks with Manufi automation.`,
  },
  {
    img: <TbTruckDelivery size='40px' color="black" />,
    title: "Streamline Logistics",
    desc: `Stay on top of your logistics operations with real-time tracking.`,
  },
  {
    img: <MdOutlinePeopleAlt size='40px' color="black" />,
    title: "Employee Management",
    desc: `Simplify payroll & scheduling with the Manufi Management suite.`,
  },
  {
    img: <RiFlowChart size='40px' color="black" />,
    title: "Automate Tasks",
    desc: `Our system automates repetitive tasks so you don't have to.`,
  },
  {
    img: <IoNewspaperOutline size='40px' color="black" />,
    title: "Generate Paperwork",
    desc: ` Generate high-quality documents quickly and eliminate errors.`,
  },
];
const FancyFeatureSeventeen = () => {
  return (
    <>
      {FeaturesContent.map((val, i) => (
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          key={i}
        >
          <div className="block-meta">
            <div className="icon d-flex align-items-end">
              <span className="ri">{val.img}</span>
            </div>
            <h4>{val.title}</h4>
            <p>{val.desc}</p>
          </div>
        </div>
        // .block-meta
      ))}
    </>
  );
};

export default FancyFeatureSeventeen;
