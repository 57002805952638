import React from "react";
import FormDemo from "../../components/form/FormDemo";
import BrandFour from "../brand/BrandFour";

const HeroBannerSeven = () => {
  return (
    <div className="hero-banner-seven lg-container">
      <div className="container">
        <div className="illustration-container">
          <img src="images/assets/manufacturing-iso.png" alt="ils" />
        </div>
        {/* End .illustration-container */}

        <div className="row">
          <div className="col-lg-6">
            <h1 className="hero-heading">
              <span>Supercharge</span>  your supply chain.
            </h1>
            <p className="hero-sub-heading">
            Unlocking potential, driving growth, delivering success.
            </p>
            <FormDemo />
            <p className="term-text">
              For teams & individuals — Web, Mac, Windows, Linux
            </p>
          </div>
        </div>
        {/* End .row */}
      </div>
      {/* End .container */}

      <div className="partner-slider-two mt-130 md-mt-80">
        <div className="container">
          <p className="text-center">
            Businesses using Manufi report a <span>32.1%</span> increase in output after just one month
          </p>
          <div className="partnerSliderTwo">
            <BrandFour />
          </div>
        </div>
      </div>
      {/* End .partner-slider-two */}

      {/* /.partner-slider-two  */}
    </div>
  );
};

export default HeroBannerSeven;
