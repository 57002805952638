import React, { useState, useEffect } from 'react';

const PriceCalculator = ({ price, pkgName, ttl, type, final }) => {
  const [users, setUsers] = useState(1);
  const total = price * users;
  const [finalno, setFinalno] = useState(0)

    useEffect (() => {
        ttl(total.toLocaleString(), pkgName)

            if (pkgName === "Starter") {
              setFinalno(final[0].toLocaleString());
            } else if (pkgName === "Growth") {
                setFinalno(final[1].toLocaleString());
            } else if (pkgName === "Enterprise") {
                setFinalno(final[2].toLocaleString());
            } else {return}

    }, [final, pkgName, total])


  const handleIncrement = () => setUsers(users + 1);
  const handleDecrement = () => setUsers(users > 1 ? users - 1 : 1);

  return (
    <div className='row justify-content-center calculate'>
      {/* <p>{pkgName} pkgName</p> */}
      <p className='trial-text'><label htmlFor="users">Number of users:</label>
      <input type="number" id="users" value={users} onChange={(e) => setUsers(e.target.value)} /></p>
      <div className="justify-content-center calculate">
      <p className='trial-button trial-b'><span>£{finalno}</span> {type === "year" ? 'per year' : 'per month'}</p>
      </div>
    </div>
  );
};

export default PriceCalculator;
